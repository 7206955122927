import React, {useState} from "react";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {ClipboardCopyIcon} from '@heroicons/react/solid'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {navigate} from "gatsby";
import {useTranslation} from "react-i18next";
import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";

export default function Widget(prop) {

    const { t } = useTranslation()

    const [copiedJs, setCopiedJs] = useState(false)
    const [copiedDiv, setCopiedDiv] = useState(false)
    const [copiedCss, setCopiedCss] = useState(false)

    const ERROR_DISPLAY_TIME = 5000 // 5 seconds

    let key = prop.location.state != undefined ? prop.location.state.tenant != undefined ? prop.location.state.tenant.Key : "" : ""

    let jsTag = "<script\n" +
        "\tdefer=\"defer\"\n" +
        "\tsrc=\"https://cdn.clickand.de/ca-meet-widget.min.js\"\n" +
        "></script>"

    let divTag = "<div\n" +
        "\tid=\"clickandmeet\"\n" +
        "\tdata-key=\"" + key + "\"\n" +
        "/>"

    let cssTag = "<link\n" +
        "\thref=\"https://cdn.clickand.de/ca-meet-widget.min.css\"\n" +
        "\trel=\"stylesheet" +
        "\">"

    const handleFinish = () => {
        navigate("/meet/dashboard")
    }

    return(
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                            {t('meet.setup.widget.title')}
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('meet.setup.widget.subtitle')}
                        </p>
                    </div>

                    <div>
                        <div
                            className=" mt-5">

                            <p className="mt-2 mb-2 text-xs text-gray-400">
                                {t('meet.setup.widget.code.js')}
                            </p>

                            <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                                <div id="header-buttons" className="py-3 px-4 flex">
                                    <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-green-500"/>
                                    {copiedJs && (
                                        <div className="ml-auto pl-3">
                                            <div className="-mx-1.5 -my-1.5">
                                                <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                    {t('label.copied')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="ml-auto pl-3 cursor-pointer">
                                        <CopyToClipboard
                                            onCopy={() => {
                                                    setCopiedJs(true)
                                                    setTimeout(function(){
                                                        setCopiedJs(false)
                                                    }.bind(this), ERROR_DISPLAY_TIME)
                                                }
                                            }
                                            text={jsTag}>
                                            <div className="-mx-1.5 -my-1.5">
                                                <ClipboardCopyIcon
                                                    className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                    aria-hidden="true" />
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div id="code-area" className="pb-4 px-4 text-white">
                                    <div className="text-xs">
                                        <SyntaxHighlighter
                                            lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                            wrapLines={true}
                                            language="html"
                                            style={dark}>
                                            {jsTag}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mt-5">

                            <p className="mt-2 mb-2 text-xs text-gray-400">
                                {t('meet.setup.widget.code.div')}
                            </p>

                            <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                                <div id="header-buttons" className="py-3 px-4 flex">
                                    <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-green-500"/>
                                    {copiedDiv && (
                                        <div className="ml-auto pl-3">
                                            <div className="-mx-1.5 -my-1.5">
                                                <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                    {t('label.copied')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="ml-auto pl-3 cursor-pointer">
                                        <CopyToClipboard
                                            onCopy={() => {
                                                    setCopiedDiv(true)
                                                    setTimeout(function(){
                                                        setCopiedDiv(false)
                                                    }.bind(this), ERROR_DISPLAY_TIME)
                                                }
                                            }
                                            text={divTag}>
                                            <div className="-mx-1.5 -my-1.5">
                                                <ClipboardCopyIcon
                                                    className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                    aria-hidden="true" />
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div id="code-area" className="pb-4 px-4 text-white">
                                    <div className="text-xs">
                                        <SyntaxHighlighter
                                            lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                            wrapLines={true}
                                            language="html"
                                            style={dark}>
                                            {divTag}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mt-5">

                            <p className="mt-2 mb-2 text-xs text-gray-400">
                                {t('meet.setup.widget.code.css')}
                            </p>

                            <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                                <div id="header-buttons" className="py-3 px-4 flex">
                                    <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                    <div className="rounded-full w-3 h-3 bg-green-500"/>
                                    {copiedCss && (
                                        <div className="ml-auto pl-3">
                                            <div className="-mx-1.5 -my-1.5">
                                                <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                    {t('label.copied')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="ml-auto pl-3 cursor-pointer">
                                        <CopyToClipboard
                                            onCopy={() => {
                                                    setCopiedCss(true)
                                                    setTimeout(function(){
                                                        setCopiedCss(false)
                                                    }.bind(this), ERROR_DISPLAY_TIME)
                                                }
                                            }
                                            text={cssTag}>
                                            <div className="-mx-1.5 -my-1.5">
                                                <ClipboardCopyIcon
                                                    className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                    aria-hidden="true" />
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div id="code-area" className="pb-4 px-4 text-white">
                                    <div className="text-xs">
                                        <SyntaxHighlighter
                                            lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                            wrapLines={true}
                                            language="html"
                                            style={dark}>
                                            {cssTag}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            onClick={handleFinish}
                            className="w-full max-w-sm lg:w-96 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {t('meet.setup.widget.button')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
